import styled from "@emotion/styled";

import { ActionBar } from "scmp-app/components/content/content-item-render/variants/home-primary/styles";
import SpotlightLogo from "scmp-app/components/home/spotlight/logo-spotlight.svg";

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  margin-block-end: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 24px;
  }
`;

export const StyledLogo = styled(SpotlightLogo)``;

export const TopStoriesComponent = styled.div`
  ${ActionBar} {
    margin-block-end: 0;
  }
`;
