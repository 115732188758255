import styled from "@emotion/styled";

import { EntityLink } from "scmp-app/components/entity-link";
import { SectionStyleHeaderMenuItem } from "scmp-app/components/section/section-style/section-style-header-menu-item";

export const StyledSectionStyleHeaderMenuItem = styled(SectionStyleHeaderMenuItem)``;

export const StyledEntityLink = styled(EntityLink)`
  padding-block-end: 8px;
  padding-inline-start: 24px;

  white-space: nowrap;
  text-transform: uppercase;

  &:first-child {
    ${StyledSectionStyleHeaderMenuItem} {
      border-block-end: 1px solid #000000;
    }
  }
`;
