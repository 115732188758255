/* eslint-disable max-lines */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { Container as AdSlotContainer } from "scmp-app/components/advertisement/ad-slots/ad-slot/styles";
import { FirstRow, SecondRow } from "scmp-app/components/comment/styles";
import { StyledFigcaption } from "scmp-app/components/content/content-cover-image/styles";
import {
  ActionBar,
  Container as HomePrimaryContainer,
  Content as HomePrimaryContent,
} from "scmp-app/components/content/content-item-render/variants/home-primary/styles";
import { EntityOnelineMenu } from "scmp-app/components/entity-oneline-menu";
import { StyledSwiperSlide } from "scmp-app/components/entity-oneline-menu/styles";
import { PlusWidget } from "scmp-app/components/plus/plus-widget";
import { Primary as SectionTopWidgetOnePrimary } from "scmp-app/components/section/section-top/variant/one/styles";
import { Container as SubsectionMenuContainer } from "scmp-app/components/subsection-menu/styles";
import { TopStoriesBlock } from "scmp-app/components/top-stories-block";
import { section as sectionData } from "scmp-app/data/section";
import { setFullViewportWidthStyles } from "scmp-app/lib/styles";

import type { HeaderVariant } from "./types";

export const AsiaContainer = styled.div`
  margin-block-start: 20px;
  padding-block-start: 20px;
  border-block-start: 1px rgba(0, 0, 0, 0.1) solid;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    padding-block-start: 32px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 0;
    padding-block-start: 0;
    border-block-start: none;
  }
`;

export const CommentContainer = styled.div`
  margin-block-start: 20px;
  padding-block-start: 20px;
  border-block-start: 1px rgba(0, 0, 0, 0.1) solid;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    padding-block-start: 32px;

    ${FirstRow}, ${SecondRow} {
      gap: 28px;
    }
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 0;
    padding-block-start: 0;
    border-block-start: none;
  }
`;

export const TrendingTopicContainer = styled.div`
  margin-block-start: 20px;
  padding-block-start: 20px;
  border-block-start: 1px rgba(0, 0, 0, 0.1) solid;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    padding-block-start: 0;
    border-block-start: none;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 0;
  }
`;

export const MultimediaContainer = styled.div`
  margin-block-start: 20px;
  padding-block-start: 32px;
  border-block-start: 1px rgba(0, 0, 0, 0.1) solid;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    padding-block-start: 0;
    border-block-start: none;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 0;
  }
`;

export const MostPopularContainer = styled.div`
  margin-block-start: 32px;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 48px;
  }
`;

export const StyledTopStoriesBlock = styled(TopStoriesBlock)`
  ${HomePrimaryContainer} {
    display: flex;
    flex-direction: column-reverse;
  }

  ${HomePrimaryContent} {
    ${props => props.theme.breakpoints.up("tablet")} {
      margin-block-start: 20px;
    }
  }

  ${StyledFigcaption} {
    display: none !important;
  }
`;

export const Container = styled.div`
  inline-size: 100%;
  margin-block-start: 20px;
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }
  ${setFullViewportWidthStyles}
`;

export const ContentContainer = styled.div`
  max-inline-size: 1024px;
  margin-inline: auto;
  padding-block-end: 32px;
  padding-inline: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-inline: 32px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    max-inline-size: 1272px;
    padding-block-end: 48px;
  }
`;

const getGridLayoutBySection = (sectionId: string) => {
  switch (sectionId) {
    case sectionData.hongKong.entityId:
    case sectionData.china.entityId:
    case sectionData.economy.entityId:
    case sectionData.business.entityId:
      return css`
        grid:
          "header header" min-content
          "hero-articles first-ads" min-content
          "hero-articles rhs-module-1" min-content
          "top-stories-1 rhs-module-1" min-content
          "top-stories-2 rhs-module-1" min-content
          "top-stories-2 ." min-content
          "top-stories-3 rhs-module-2" min-content
          "top-stories-4 rhs-module-2" min-content
          "top-stories-4 second-ads" 1fr
          "in-house-ads-newsletter-1 second-ads" min-content
          "in-house-ads-newsletter-1 ." min-content
          "spotlight rhs-module-3" min-content
          "newsletter-widget rhs-module-3" min-content
          "subsection-1 rhs-module-3" min-content
          "subsection-1 third-ads" min-content
          "subsection-1 ." 0
          "subsection-1 ." min-content
          "subsection-2 ." 48px
          "subsection-2 rhs-module-4" min-content
          "subsection-3 rhs-module-4" min-content
          "subsection-3 rhs-module-5" min-content
          "subsection-3 fourth-ads" min-content
          "subsection-4 fourth-ads" min-content
          "subsection-5 rhs-module-6" min-content
          "subsection-5 fifth-ads" min-content
          "in-house-ads-newsletter-2 ." min-content
          "subsection-6 sixth-ads" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);
      `;
    case sectionData.tech.entityId:
    case sectionData.news.peopleAndCulture.entityId:
    case sectionData.comment.entityId:
    case sectionData.kPop.entityId:
    case sectionData.sport.entityId:
      return css`
        grid:
          "header header" min-content
          "hero-articles rhs-module-1" min-content
          "top-stories-1 rhs-module-1" min-content
          "top-stories-2 first-ads" min-content
          "top-stories-2 ." min-content
          "top-stories-3 rhs-module-2" min-content
          "top-stories-4 rhs-module-2" min-content
          "top-stories-4 second-ads" min-content
          "in-house-ads-newsletter-1 ." min-content
          "spotlight rhs-module-3" min-content
          "newsletter-widget rhs-module-3" min-content
          "subsection-1 rhs-module-3" min-content
          "subsection-1 ." 32px
          "subsection-1 rhs-module-4" min-content
          "subsection-1 third-ads" min-content
          "subsection-1 ." 0
          "subsection-1 ." min-content
          "subsection-2 ." min-content
          "subsection-3 ." 48px
          "subsection-3 fourth-ads" min-content
          "subsection-3 ." min-content
          "subsection-4 ." 16px
          "subsection-4 fifth-ads" min-content
          "subsection-4 ." min-content
          "subsection-5 rhs-module-5" min-content
          "subsection-5 sixth-ads" min-content
          "in-house-ads-newsletter-2 ." min-content
          "subsection-6 rhs-module-6" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);
      `;
    case sectionData.thisWeekInAsia.entityId:
    case sectionData.asia.entityId:
      return css`
        grid:
          "header header" min-content
          "hero-articles rhs-module-1" min-content
          "top-stories-1 rhs-module-1" min-content
          "top-stories-2 rhs-module-1" min-content
          "top-stories-2 first-ads" min-content
          "top-stories-2 ." 0
          "top-stories-2 ." min-content
          "top-stories-3 rhs-module-2" min-content
          "top-stories-4 rhs-module-2" min-content
          "top-stories-4 second-ads" min-content
          "top-stories-4 ." min-content
          "in-house-ads-newsletter-1 ." min-content
          "spotlight rhs-module-3" min-content
          "newsletter-widget rhs-module-3" min-content
          "subsection-1 rhs-module-3" min-content
          "subsection-1 third-ads" min-content
          "subsection-1 ." 0
          "subsection-1 ." min-content
          "subsection-2 ." 48px
          "subsection-2 rhs-module-4" min-content
          "subsection-3 rhs-module-4" min-content
          "subsection-3 rhs-module-4" 32px
          "subsection-3 rhs-module-5" min-content
          "subsection-3 rhs-module-5" 32px
          "subsection-3 fourth-ads" min-content
          "subsection-4 fourth-ads" min-content
          "subsection-4 ." min-content
          "subsection-5 rhs-module-6" min-content
          "subsection-5 fifth-ads" min-content
          "subsection-5 ." min-content
          "in-house-ads-newsletter-2 ." min-content
          "subsection-6 ." 48px
          "subsection-6 sixth-ads" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);
      `;
    case sectionData.olympic.entityId:
      return css`
        grid:
          "header header" min-content
          "hero-articles first-ads" min-content
          "top-stories-1 ." min-content
          "top-stories-2 ." min-content
          "top-stories-3 ." min-content
          "top-stories-4 ." min-content
          "in-house-ads-newsletter-1 ." min-content
          "spotlight rhs-module-1" min-content
          "newsletter-widget rhs-module-1" min-content
          "subsection-1 rhs-module-1" min-content
          "subsection-1 ." 32px
          "subsection-1 second-ads" min-content
          "subsection-1 ." min-content
          "subsection-2 rhs-module-2" min-content
          "subsection-3 rhs-module-2" min-content
          "subsection-3 ." 32px
          "subsection-3 third-ads" min-content
          "subsection-3 ." min-content
          "subsection-4 ." min-content
          "subsection-4 ." 32px
          "subsection-5 rhs-module-3" min-content
          "subsection-5 ." 32px
          "subsection-5 fourth-ads" min-content
          "in-house-ads-newsletter-2 ." min-content
          "subsection-6 ." 16px
          "subsection-6 fifth-ads" min-content
          "subsection-6 rhs-module-4" min-content
          "subsection-6 rhs-module-5" min-content
          "subsection-6 sixth-ads" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);

        ${RHSModule1}, ${SecondAds}, ${ThirdAds} {
          margin-block-start: 0;
        }

        ${RHSModule3} {
          > div {
            margin-block-start: 32px;
          }
        }

        MostPopularContainer ${FifthAds} {
          display: none;
        }

        ${Subsection2}, ${Subsection3}, ${Subsection4}, ${Subsection5} {
          margin-block-start: 32px;
        }
      `;
    default:
      return css`
        grid:
          "header header" min-content
          "hero-articles rhs-module-1" min-content
          "top-stories-1 rhs-module-1" min-content
          "top-stories-1 first-ads" min-content
          "top-stories-2 rhs-module-2" min-content
          "top-stories-3 rhs-module-2" min-content
          "top-stories-3 rhs-module-2" min-content
          "top-stories-3 second-ads" 1fr
          "top-stories-4 second-ads" min-content
          "in-house-ads-newsletter-1 ." min-content
          "spotlight rhs-module-3" min-content
          "newsletter-widget rhs-module-3" min-content
          "subsection-1 rhs-module-3" min-content
          "subsection-1 ." 32px
          "subsection-1 rhs-module-4" min-content
          "subsection-1 third-ads" min-content
          "subsection-1 ." 0
          "subsection-1 ." min-content
          "subsection-2 rhs-module-5" min-content
          "subsection-2 fourth-ads" min-content
          "subsection-2 ." min-content
          "subsection-3 ." 16px
          "subsection-3 ." min-content
          "subsection-4 ." 16px
          "subsection-4 fifth-ads" min-content
          "subsection-4 ." min-content
          "subsection-5 sixth-ads" min-content
          "in-house-ads-newsletter-2 ." min-content
          "subsection-6 rhs-module-6" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);
      `;
  }
};

type ContentWrapperProps = {
  $sectionId?: string;
};

export const ContentWrapper = styled.div<ContentWrapperProps>`
  display: grid;
  grid:
    "header" min-content
    "hero-articles" min-content
    "first-ads" min-content
    "top-stories-1" min-content
    "rhs-module-1" min-content
    "second-ads" min-content
    "top-stories-2" min-content
    "rhs-module-2" min-content
    "third-ads" min-content
    "top-stories-3" min-content
    "top-stories-4" min-content
    "in-house-ads-newsletter-1" min-content
    "spotlight" min-content
    "newsletter-widget" min-content
    "rhs-module-3" min-content
    "fourth-ads" min-content
    "subsection-1" min-content
    "subsection-2" min-content
    "rhs-module-4" min-content
    "fifth-ads" min-content
    "subsection-3" min-content
    "subsection-4" min-content
    "in-house-ads-newsletter-2" min-content
    "rhs-module-5" min-content
    "sixth-ads" min-content
    "subsection-5" min-content
    "rhs-module-6" min-content
    "subsection-6" min-content
    "subsection-rest" min-content
    / minmax(0, 1fr);

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    column-gap: 40px;

    ${props => getGridLayoutBySection(props.$sectionId ?? "")};
  }

  time {
    line-height: 14px;
  }
`;

export const StyledEntityOnelineMenu = styled(EntityOnelineMenu)`
  ${StyledSwiperSlide} {
    &.swiper-slide {
      &:not(:last-child) {
        margin-inline-end: 16px;
      }
    }
  }
`;

type HeaderProps = {
  $variant?: HeaderVariant;
};

export const Header = styled.div<HeaderProps>`
  grid-area: header;

  border-block-end: 1px rgba(0, 0, 0, 0.1) solid;

  padding-block-end: 16px;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-block-end: 24px;
  }

  h1 {
    margin-block-end: 16px;

    font-weight: 700;
    font-size: 24px;
    font-family: ${fontRobotoCondensed};
    line-height: 120%;
    text-transform: uppercase;

    ${props => props.theme.breakpoints.up("tablet")} {
      margin-block-end: 8px;

      font-size: 32px;
    }

    ${props => props.theme.breakpoints.up("homeDesktop")} {
      font-size: 44px;
    }
  }

  ${props =>
    props.$variant === "twia" &&
    css`
      text-align: center;

      ${StyledEntityOnelineMenu} {
        display: flex;
        justify-content: center;
      }
    `};
`;

export const HeroArticles = styled.div`
  grid-area: hero-articles;

  margin-block-start: 16px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }

  ${SectionTopWidgetOnePrimary} {
    margin-block-end: 32px;
    padding-block-end: 32px;
    border-block-end: 1px solid rgba(0, 0, 0, 0.1);

    ${props => props.theme.breakpoints.up("homeDesktop")} {
      margin-block-end: 0;
      padding-block-end: 0;
      border-block-end: none;
    }
  }
`;

export const TopStories = styled.div`
  margin-block-start: 20px;
  padding-block-start: 20px;
  border-block-start: 1px rgba(0, 0, 0, 0.1) solid;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    padding-block-start: 32px;
  }

  ${ActionBar} {
    margin-block-end: 0;
  }
`;

export const TopStories1 = styled(TopStories)`
  grid-area: top-stories-1;

  margin-block-start: 32px;
  padding-block-start: 32px;
`;

export const TopStories2 = styled(TopStories)`
  grid-area: top-stories-2;
`;

export const TopStories3 = styled(TopStories)`
  grid-area: top-stories-3;
`;

export const TopStories4 = styled(TopStories)`
  grid-area: top-stories-4;
`;

export const InHouseAdsAndNewsletter1 = styled.div`
  grid-area: in-house-ads-newsletter-1;

  margin-block: 10px 46px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block: 32px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block: 48px;
  }
`;

export const InHouseAdsAndNewsletter2 = styled.div`
  grid-area: in-house-ads-newsletter-2;

  margin-block: 30px 46px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block: 32px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block: 48px 0;
  }
`;

export const Spotlight = styled.div`
  grid-area: spotlight;

  padding-block-start: 16px;
  border-block-start: 1px #000000 solid;
`;

export const Newsletter = styled.div`
  grid-area: newsletter-widget;

  margin-block-start: 32px;

  background-color: rgba(0, 0, 0, 0.04);

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 24px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 48px;
  }
`;

const Subsection = styled.div`
  margin-block-start: 32px;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 48px;
  }

  ${SubsectionMenuContainer} {
    grid:
      "section-name-follow" min-content
      / 100%;
  }
`;

export const Subsection1 = styled(Subsection)`
  grid-area: subsection-1;
`;

export const Subsection2 = styled(Subsection)`
  grid-area: subsection-2;
`;

export const Subsection3 = styled(Subsection)`
  grid-area: subsection-3;
`;

export const Subsection4 = styled(Subsection)`
  grid-area: subsection-4;
`;

export const Subsection5 = styled(Subsection)`
  grid-area: subsection-5;
`;

export const Subsection6 = styled(Subsection)`
  grid-area: subsection-6;
`;

export const SubsectionRest = styled.div`
  grid-area: subsection-rest;

  display: flex;
  flex-flow: column nowrap;

  ${SubsectionMenuContainer} {
    margin-block-start: 0;
  }

  ${SubsectionMenuContainer} {
    grid:
      "section-name-follow" min-content
      / 100%;
  }
`;

export const RHSModule1 = styled.div`
  grid-area: rhs-module-1;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 32px;
  }
`;

export const RHSModule2 = styled.div`
  grid-area: rhs-module-2;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 32px;
  }
`;

export const RHSModule3 = styled.div`
  grid-area: rhs-module-3;
`;

export const RHSModule4 = styled.div`
  grid-area: rhs-module-4;
`;

export const RHSModule5 = styled.div`
  grid-area: rhs-module-5;
`;

export const RHSModule6 = styled.div`
  grid-area: rhs-module-6;
`;

export const FirstAds = styled.div`
  grid-area: first-ads;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 32px;
    ${AdSlotContainer} {
      block-size: auto;
    }
  }
`;

export const SecondAds = styled.div`
  grid-area: second-ads;

  margin-block-start: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    ${AdSlotContainer} {
      block-size: auto;
    }
  }
`;

export const ThirdAds = styled.div`
  grid-area: third-ads;

  margin-block-start: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    ${AdSlotContainer} {
      block-size: auto;
    }
  }
`;

export const FourthAds = styled.div`
  grid-area: fourth-ads;

  margin-block-start: 32px;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 0;
  }

  ${AdSlotContainer} {
    block-size: auto;
  }
`;

export const FifthAds = styled.div`
  grid-area: fifth-ads;

  margin-block-start: 32px;
`;

export const SixthAds = styled.div`
  grid-area: sixth-ads;

  margin-block-start: 32px;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    display: none;
  }
`;

export const StyledPlusWidget = styled(PlusWidget)`
  margin-block-start: 32px;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 0;
  }
`;
