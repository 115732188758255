import type { FunctionComponent } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import type { sectionTopPostMagazineQuery } from "scmp-app/queries/__generated__/sectionTopPostMagazineQuery.graphql";

import { getDefaultAdSlotProps, getWidgetsMap } from "./helpers";
import {
  Subsection1,
  Subsection2,
  Subsection3,
  Subsection4,
  Subsection5,
  Subsection6,
  Subsection7,
} from "./styles";

type Props = {
  adZone?: string;
  excludedArticleIds: string[];
  sectionId: string;
};

export const PostMagazineSectionTop: FunctionComponent<Props> = ({
  adZone = "default",
  excludedArticleIds,
  sectionId,
}) => {
  const data = useLazyLoadQuery<sectionTopPostMagazineQuery>(
    graphql`
      query sectionTopPostMagazineQuery($excludedArticleIds: [String]!) {
        sectionTopPostMagazine: section(filter: { entityId: "71" }) {
          subSections {
            entityId
            items(first: 6) {
              edges {
                node {
                  ... on Section {
                    topStories {
                      items(first: 10, exclude: { entityIds: $excludedArticleIds }) {
                        edges {
                          ...onePostMagSectionTopWidgetArticles
                          ...twoPostMagSectionTopWidgetArticles
                          ...threePostMagSectionTopWidgetArticles
                        }
                      }
                    }
                    entityId
                    ...onePostMagSectionTopWidgetSection
                    ...twoPostMagSectionTopWidgetSection
                    ...threePostMagSectionTopWidgetSection
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      excludedArticleIds,
    },
    { fetchPolicy: "store-or-network" },
  );

  const subSectionComponents = [
    Subsection1,
    Subsection2,
    Subsection3,
    Subsection4,
    Subsection5,
    Subsection6,
    Subsection7,
  ];

  const subSections = data.sectionTopPostMagazine?.subSections?.items?.edges ?? [];
  const defaultAdSlotProps = getDefaultAdSlotProps(sectionId, adZone);
  const widgetsMap = getWidgetsMap(defaultAdSlotProps);

  return (
    <>
      {subSections.map(({ node }, index) => {
        const SubSectionComponent = subSectionComponents[index + 1];
        const SectionTopWidget = widgetsMap[(index + 1) % Object.keys(widgetsMap).length];
        const { component: SectionTopComponent, props: ComponentProps } = SectionTopWidget;

        return (
          <SubSectionComponent key={node.entityId}>
            <SectionTopComponent
              {...ComponentProps}
              reference={node.topStories?.items?.edges ?? []}
              sectionReference={node}
            />
          </SubSectionComponent>
        );
      })}
    </>
  );
};

PostMagazineSectionTop.displayName = "PostMagazineSectionTop";
