import styled from "@emotion/styled";

const Subsection = styled.div``;

export const Subsection1 = styled(Subsection)`
  grid-area: subsection-1;
`;

export const Subsection2 = styled(Subsection)`
  grid-area: subsection-2;
`;

export const Subsection3 = styled(Subsection)`
  grid-area: subsection-3;
`;

export const Subsection4 = styled(Subsection)`
  grid-area: subsection-4;
`;

export const Subsection5 = styled(Subsection)`
  grid-area: subsection-5;
`;

export const Subsection6 = styled(Subsection)`
  grid-area: subsection-6;
`;

export const Subsection7 = styled(Subsection)`
  grid-area: subsection-7;
`;
