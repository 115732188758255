import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { postMagazineSectionWidgetTwoContent$key } from "scmp-app/queries/__generated__/postMagazineSectionWidgetTwoContent.graphql";

import {
  Container,
  ContentContainer,
  CoverImage,
  Headline,
  StyledCoverEntityLink,
  StyledEntityLink,
  Topic,
} from "./styles";

export type Props = {
  reference: postMagazineSectionWidgetTwoContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_, topicLinkVariant }, reference) => {
    const content = useFragment(
      graphql`
        fragment postMagazineSectionWidgetTwoContent on Content {
          entityId
          urlAlias
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(
              withCoverImage: true
              withCoverImageSize1200x800: true
              withCoverImageSize800x1200: true
              withHeadline: true
              withTopicLink: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content, topicLinkVariant);
    const topic = providers.topicLink?.({ preferShortName: true });
    const headline = providers.headline({ hideTooltip: true, preferSocialHeadline: true });

    return (
      <Container className={className} ref={reference}>
        <StyledCoverEntityLink
          reference={content}
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
        >
          <CoverImage>
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "size1200x800",
                mobileUp: "size800x1200",
                tabletUp: "size1200x800",
              },
            })}
          </CoverImage>
        </StyledCoverEntityLink>
        <ContentContainer>
          <StyledEntityLink
            reference={content}
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
          >
            <Headline>{headline}</Headline>
          </StyledEntityLink>
          <Topic>{topic}</Topic>
        </ContentContainer>
      </Container>
    );
  },
);

Component.displayName = "ContentItemPostMagazineSectionWidgetTwo";

export const ContentItemPostMagazineSectionWidgetTwo = Component;
