import styled from "@emotion/styled";

import { HeaderLogo } from "scmp-app/components/section/section-style/styles";
import { setFullViewportWidthStyles } from "scmp-app/lib/styles";

export const Container = styled.div`
  ${setFullViewportWidthStyles()}

  margin-block-end: 10px;
  padding-block-start: 8px;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: none;
  }
`;

export const StyledLogo = styled(HeaderLogo)``;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-block-end: 5px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 12px;
  }
  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block-end: 0;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;

  inline-size: 100%;
  min-block-size: 26px;
  padding-inline-end: 16px;

  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;
