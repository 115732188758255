import { theme } from "@product/scmp-sdk";

import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";
import {
  SectionTopWidgetOne,
  SectionTopWidgetThree,
  SectionTopWidgetTwo,
} from "scmp-app/components/post-magazine/section-top-widget";

import type { WidgetMap } from "./types";

export const getDefaultAdSlotProps = (sectionId: string, adZone: string): Partial<AdSlotProps> => ({
  targeting: {
    paid: TargetingPaidType.Free,
    scsid: [sectionId],
  },
  withBackground: true,
  withLabel: true,
  withSticky: true,
  zone: adZone,
});

export const getWidgetsMap = (
  defaultAdSlotProps: Partial<AdSlotProps>,
): { [key: number]: WidgetMap } => ({
  0: {
    component: SectionTopWidgetOne,
    props: {
      adsConfigs: [
        [
          {
            ...defaultAdSlotProps,
            adUnit: "d_lrec2",
            breakpoint: theme.breakpoints.up("tablet"),
            sizes: [[300, 250], "fluid"],
          },
          {
            ...defaultAdSlotProps,
            adUnit: "m_lrec1",
            breakpoint: theme.breakpoints.only("mobile"),
            sizes: [[300, 250], "fluid"],
          },
        ],
        [
          {
            ...defaultAdSlotProps,
            adUnit: "d_native2a",
            breakpoint: theme.breakpoints.up("tablet"),
            sizes: [[1, 1], "fluid"],
            withBackground: false,
            withLabel: false,
          },
          {
            ...defaultAdSlotProps,
            adUnit: "m_native2a",
            breakpoint: theme.breakpoints.only("mobile"),
            sizes: [[1, 1], "fluid"],
            withBackground: false,
            withLabel: false,
          },
        ],
      ],
      label: "Latest",
      variant: "withFiveArticle",
    },
  },
  1: {
    component: SectionTopWidgetOne,
    props: { variant: "withThreeArticle" },
  },
  2: {
    component: SectionTopWidgetOne,
    props: {
      adsConfigs: [
        [
          {
            ...defaultAdSlotProps,
            adUnit: "d_lrec3",
            breakpoint: theme.breakpoints.up("tablet"),
            sizes: [[300, 250], "fluid"],
          },
          {
            ...defaultAdSlotProps,
            adUnit: "m_lrec3",
            breakpoint: theme.breakpoints.only("mobile"),
            sizes: [[300, 250], "fluid"],
          },
        ],
      ],
    },
  },
  3: {
    component: SectionTopWidgetOne,
    props: { variant: "withThreeArticle" },
  },
  4: {
    component: SectionTopWidgetTwo,
  },
  5: {
    component: SectionTopWidgetOne,
    props: {
      adsConfigs: [
        [
          {
            ...defaultAdSlotProps,
            adUnit: "d_lrec4",
            breakpoint: theme.breakpoints.up("tablet"),
            sizes: [[300, 250], "fluid"],
          },
          {
            ...defaultAdSlotProps,
            adUnit: "m_banner4",
            breakpoint: theme.breakpoints.only("mobile"),
            sizes: [[300, 250], "fluid"],
          },
        ],
      ],
    },
  },
  6: {
    component: SectionTopWidgetThree,
  },
});
