import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { postMagazineSectionWidgetThreeContent$key } from "scmp-app/queries/__generated__/postMagazineSectionWidgetThreeContent.graphql";

import {
  Container,
  CoverImage,
  Headline,
  StyledCoverEntityLink,
  StyledEntityLink,
  Topic,
} from "./styles";

export type Props = {
  reference: postMagazineSectionWidgetThreeContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_, topicLinkVariant }, reference) => {
    const content = useFragment(
      graphql`
        fragment postMagazineSectionWidgetThreeContent on Content {
          entityId
          urlAlias
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(
              withCoverImage: true
              withCoverImageSize500x500: true
              withHeadline: true
              withTopicLink: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content, topicLinkVariant);
    const topic = providers.topicLink?.({ preferShortName: true });
    const headline = providers.headline({ hideTooltip: true, preferSocialHeadline: true });

    return (
      <Container className={className} ref={reference}>
        <StyledCoverEntityLink
          reference={content}
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
        >
          <CoverImage>
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "size500x500",
                mobileUp: "size500x500",
                tabletUp: "size500x500",
              },
            })}
          </CoverImage>
        </StyledCoverEntityLink>

        <StyledEntityLink
          reference={content}
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
        >
          <Headline>{headline}</Headline>
        </StyledEntityLink>
        <Topic>{topic}</Topic>
      </Container>
    );
  },
);

Component.displayName = "ContentItemPostMagazineSectionWidgetThree";

export const ContentItemPostMagazineSectionWidgetThree = Component;
